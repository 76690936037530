.project-list{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin-inline: auto;
    gap: 3rem;

    
    @media (min-width:48em) {
        width: 100%;
        grid-template-columns: 1fr 1fr;
    }
    
    // @media (min-width:75em) {
    //     grid-template-columns: 1fr 1fr 1fr;
    // }

    
    // .project-item:nth-child(4){
    //     @media (min-width:48em) {
    //         grid-column: 1/3;
    //         grid-row: 2/4;
    //     }

    // }
}