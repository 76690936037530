.hero{
    padding-top: 20rem;
    position: relative;
    min-height: 600px;
    height: 100vh;
    max-height: 900px;
    padding-inline: 1rem;

    @media (min-width:48em) {
        padding-inline: 8rem;
    }
    
    @media (min-width:62em) {
        padding-inline: 12rem;
        font-size: 1.25rem;
    }
    
    @media (min-width:75em) {
        // padding-top: 20rem;
    }

    &::before{
        content: '';
        position: absolute;
        top: 0%;
        right: 0%;
        left: 0%;
        bottom: 0%;
        background-image: url('../Asset/pattern4.png');
        opacity: 0.05;
    }

    .hero-content{
        max-width: 1000px;
        margin-inline: auto;
    }

    h1{
        font-size: 3rem;
        font-weight: bold;
        color: var(--nc-);

        @media (min-width:62em) {
            font-size: 5rem;
        }
    }

    .pro{
        margin-bottom: 2rem;

        @media (min-width:62em) {
            font-size: 1.5rem;
            margin-bottom: 3rem;
            margin-top: 0.5rem;
        }

    }
}

