
.contact-content{
    margin-inline: auto;

    p{
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
        
        @media (min-width:48em) {
            font-size: 1.5rem;
        }
        
        @media (min-width:62em) {
            font-size: 1.75rem;
            // font-size: 2rem;
        }
    }
}