.project-item{
    width: 100%;
    padding: 1rem;
    display: block;
    text-decoration: none;
    margin-inline: auto;
    margin-top: auto;
    
    &:hover{
        background-color:rgba(227, 246, 245, 0.064);

        .project-role{
            .role{
                transform: translate3d(0px, -20px, 0px);
                transition: transform 0.5s ease-in-out;
            }
            .view{
                transform: translate3d(0px, -20px, 0px);
                transition: transform 0.5s ease-in-out;
            }
        }
        
    }
    
    .project-image{
        width: 100%;
        height: 100%;
        background-color: #8c8686;
    }
    .project-title{
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--nc-);
    }
    
    .project-role{
        font-size: 1rem;
        font-weight: 200;
        color: var(--pc-two);
        overflow: hidden;
        height: 1rem;
        width: fit-content;

        .role{
            transform: translate3d(0px, 0px, 0px);
            transition: transform 0.5s ease-in-out;
        }
        .view{
            transform: translate3d(0px, 0px, 0px);
            transition: transform 0.5s ease-in-out;
            color: var(--sc-two);
        }

    }
}
