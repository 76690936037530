
.logo{
    text-decoration: none;
    color: var(--nc-);
    font-weight: bold;
    font-size: 2rem;
    border: 2px solid var(--nc-);
    padding: 0 .5rem;
    padding: 0.5rem 1rem;

}

.preloader{
    color: var(--nc-);
    background-color: var(--pc-one);
    text-decoration: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 2rem;
    padding: 0.5rem 1rem;
    width:fit-content ;
    border: none;
    z-index: 3;
    
    &::before{
        // background-color: var(--pc-one);
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        border: 3px solid var(--nc-);
        animation: loading infinite 3s ease-in-out;
        z-index: -1;
    }
}


@keyframes loading {
    from{
        transform: rotate(0deg);

    }
    to{
            transform: rotate(360deg);
        }
    
}
// .preload{
//     text-decoration: none;
//     color: var(--nc-);
//     font-weight: bold;
//     font-size: 2rem;
//     padding: 0 .5rem;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     border: 2px solid var(--pc-one);
//     pointer-events: none;


    
//     &::before{
//         content: '';
//         position: absolute;
//         top: 0%;
//         left: 0%;
//         background-color: white;
//         height: 100%;
//         width: 100%;
//         border: 2px solid var(--nc-);
//         border-top-width:0 ;
//         border-right-width:0 ;
//         border-bottom-width:0 ;
//         border-left-width:0 ;
        
//         animation-name: preloading;
//         animation-duration: 5s;
        
//         animation-delay: 2s;
        
        
//     }
    
    
    
// }



//     @keyframes preloading {
//         // 20%{
//         //     border-top-width:100 ;
//         // }
//         // 40%{
//         //     border-right-width:100 ;
//         // }
//         // 60%{
//         //     border-bottom-width:100 ;
//         // }
//         // 80%{
//         //     border-left-width:100 ;
//         // }
//         // 100%{
//         //     border-radius: 50% ;
//         // }
//         // from{
//         //     background-color: red;
//         // }
//         // to{
//         //     background-color: aqua;
//         // }

//         from {
//             rotate: 45deg;
//         }

//         to{
//             rotate: 32deg;
//         }
//     }